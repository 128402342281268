import React from "react"

const Content = () => {
  return (
    <section className="privacy-policy-content">
      <div className="container-fluid">
        <h2>
          SERWISU INTERNETOWEGO
          <br /> DOSTĘPNEGO POD ADRESEM: trustinvestmentprojekt14.pl
        </h2>

        <h3>§ 1 Definicje</h3>
        <p>
          Użyte w niniejszym regulaminie pojęcia pisane wielką literą mają
          następujące znaczenie:
        </p>
        <ol>
          <li>
            <strong>Inwestycja</strong> – oznacza zawarcie umowy inwestycyjnej
            pożyczka przez Inwestora na rzecz Usługodawcy w celu realizacji
            projektu deweloperskiego przez Usługodawcę;
          </li>
          <li>
            <strong>Konto</strong> - oznacza wyodrębnioną część Serwisu opisaną
            jako panel klienta przydzieloną danemu Użytkownikowi, identyfikowaną
            za pomocą adresu e-mail, dzięki której Użytkownik może dokonywać
            określonych działań w ramach Serwisu;
          </li>
          <li>
            <strong>Polityka prywatności</strong> – oznacza politykę prywatności
            Serwisu określającą zasady przetwarzania danych osobowych oraz
            określającą zasady korzystania z plików cookies, dostępną pod
            adresem: https:// trustinvestmentprojekt14.pl;
          </li>
          <li>
            <strong>Regulamin</strong> – niniejszy regulamin świadczenia usług
            drogą elektroniczną;
          </li>
          <li>
            <strong>Umowa inwestycyjna pożyczka</strong> – oznacza umowę zawartą
            pomiędzy Usługodawcą, a inwestorem na podstawie której Użytkownik
            udziela pożyczki pieniężnej Usługodawcy na realizację Inwestycji, a
            Usługodawca zobowiązuje się zwrócić kwotę pożyczki wraz z
            wynagrodzeniem za korzystanie z kapitału;
          </li>
          <li>
            <strong>Umowa inwestycyjna pożyczka powyżej 30.000,00 zł</strong> –
            oznacza Umowę inwestycyjną pożyczka zawartą na kwotę powyżej
            25.00,00 zł, która może zostać zabezpieczona udzielonym poręczeniem,
            które zostanie zabezpieczone zastawem zwykłym.
          </li>
          <li>
            <strong>Usługodawca</strong> – Trust Investment Projekt 14 spółka z
            ograniczoną odpowiedzialnością z siedzibą w Kielcach przy ul.
            Robotniczej nr 1, 25-662 Kielce, wpisana do rejestru przedsiębiorców
            Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w
            Kielcach, X Wydział Gospodarczy Krajowego Rejestru Sądowego pod
            numerem KRS: 0000970028, NIP: 9592051928, REGON: 521970376;
          </li>
          <li>
            <strong>Usługi</strong> – usługi świadczone przez Usługodawcę drogą
            elektroniczną w ramach Serwisu, szczegółowo określone w Regulaminie;
          </li>
          <li>
            <strong>Użytkownik</strong> – oznacza osobę fizyczną odwiedzającą
            Serwis lub korzystającą z funkcjonalności Serwisu we własnym imieniu
            lub jako reprezentant osoby prawnej;
          </li>
          <li>
            <strong>RODO</strong> – oznacza przepisy Rozporządzenia Parlamentu
            Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
            sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
            osobowych i w sprawie swobodnego przepływu takich danych oraz
            uchylenia dyrektywy 95/46/WE.
          </li>
        </ol>

        <h3>§ 2 Postanowienia ogólne</h3>
        <ol>
          <li>
            Przedmiotem Regulaminu jest określenie warunków i zasad korzystania
            z Serwisu internetowego Usługodawcy dostępnego pod adresem: https://
            trustinvestmentprojekt14.pl.
          </li>
          <li>
            Informacje na temat zasad przetwarzania danych pozyskanych w związku
            z korzystaniem z Serwisu zawiera Polityka Prywatności.
          </li>
          <li>
            Podmiotem będącym właścicielem oraz administratorem Serwisu jest:
            spółka{" "}
            <strong>
              Trust Investment Projekt 14 spółka z ograniczoną
              odpowiedzialnością
            </strong>{" "}
            z siedzibą w Kielcach przy ul. Robotniczej nr 1, 25-662 Kielce,
            wpisana do rejestru przedsiębiorców Krajowego Rejestru Sądowego
            prowadzonego przez Sąd Rejonowy w Kielcach, X Wydział Gospodarczy
            Krajowego Rejestru Sądowego pod numerem KRS: 0000970028, NIP:
            9592051928, REGON: 521970376.
          </li>
          <li>
            Każdy Użytkownik może korzystać z Serwisu w ograniczonym zakresie
            przed zarejestrowaniem się Konta oraz w pełnym zakresie po dokonaniu
            rejestracji Konta.
          </li>
          <li>
            Warunkiem korzystania z Serwisu przez Użytkownika jest zapoznanie
            się z treścią Regulaminu i jego akceptacja. Rozpoczęcie korzystania
            z Serwisu oraz usług świadczonych za jego pośrednictwem jest
            równoznaczne z akceptacją postanowień Regulaminu.
          </li>
          <li>
            Regulamin jest nieodpłatnie udostępniany w Serwisie, za
            pośrednictwem odnośnika dostępnego na stronie głównej Serwisu.
          </li>
          <li>
            Treść Regulaminu może zostać utrwalona przez Użytkownika poprzez
            wydrukowanie, zapisanie na nośniku lub pobranie w każdej chwili ze
            strony Serwisu.
          </li>
        </ol>

        <h3>
          § 3 Wymagania techniczne niezbędne do współpracy z systemem
          teleinformatycznym Usługodawcy
        </h3>
        <p>Do prawidłowego korzystania z usług Serwisu niezbędne jest:</p>
        <ol>
          <li>
            posiadanie dostępu do urządzeń, mających możliwość połączenia z
            Internetem;
          </li>
          <li>połączenie z siecią Internet;</li>
          <li>posiadanie konta e-mail;</li>
          <li>
            korzystanie z przeglądarki internetowej, pozwalającej na
            wyświetlanie dokumentów hipertekstowych, powiązanych w sieci
            Internet przez sieciową usługę www oraz 3 obsługującej JavaScript i
            akceptującej pliki cookies (zalecane: Microsoft Edge, Opera, Chrome,
            Firefox, Safari). Rekomendowane jest korzystanie z najnowszej wersji
            przeglądarek internetowych.
          </li>
        </ol>

        <h3>§ 4 Korzystanie z Serwisu</h3>
        <ol>
          <li>
            Użytkownik jest uprawniony do bezpłatnego korzystania z treści
            udostępnionych w Serwisie bez konieczności rejestracji Konta.
          </li>
          <li>
            Treści publikowane w Serwisie są udostępniane Użytkownikowi
            wyłącznie w celach informacyjnych i marketingowych.
          </li>
          <li>Publikowane w Serwisie treści nie stanowią:</li>
          <ol>
            <li>
              oferty, w tym oferty w rozumieniu art. 66 Ustawy z dnia 23
              kwietnia 1964 r. Kodeks cywilny;
            </li>
            <li>
              usługi finansowania społecznościowego w rozumieniu Ustawy z dnia 7
              lipca 2022 r. o finansowaniu społecznościowym dla przedsięwzięć
              gospodarczych i pomocy kredytobiorcom oraz rozporządzenia
              Parlamentu Europejskiego i Rady (UE) 2020/1503 z dnia 7
              października 2020 r. w sprawie europejskich dostawców finansowania
              społecznościowego dla przedsięwzięć gospodarczych oraz zmieniające
              rozporządzenie (UE) 2017/1129;
            </li>
            <li>
              jakiejkolwiek rekomendacji inwestycyjnej, porady prawnej czy
              podatkowej.
            </li>
          </ol>
          <li>
            Wszelkie prezentowane w Serwisie dane finansowe stanowią prognozę
            Inwestycji. Wskazana Stopa zwrotu Inwestycji nie stanowi
            gwarantowanego wyniku inwestycji, a ostateczny wynik obciążony może
            być wieloma czynnikami ryzyka, które mogą prowadzić do uzyskania
            zysku niższego niż przedstawiana w materiałach stopa zwrotu
            inwestycji.
          </li>
          <li>
            Wszystkie informacje zawarte w treściach i materiałach publikowanych
            w Serwisie są prezentowane w dobrej wierze i w przekonaniu o ich
            zgodności ze stanem faktycznym i prawnym według stanu na dzień ich
            publikacji w Serwisie.
          </li>
        </ol>

        <h3>§ 5 Rejestracja Konta Użytkownika w Serwisie</h3>
        <ol>
          <li>
            W celu korzystania z Serwisu oraz usług świadczonych za jego
            pośrednictwem w pełnym zakresie, konieczne jest dokonanie
            rejestracji Konta przez Użytkownika.
          </li>
          <li>Rejestracja w Serwisie jest dobrowolna i wolna od opłat.</li>
          <li>
            Rejestracja Konta Użytkownika w Serwisie obejmuje rejestrację Konta
            poprzez wypełnienie formularza rejestracji dostępnego w Serwisie.
          </li>
          <li>Podczas rejestracji Konta Użytkownik:</li>
          <ol>
            <li>
              oświadcza, że zapoznał się Regulaminem oraz akceptuje jego treść;
            </li>
            <li>
              oświadcza, że zapoznał się z Polityką prywatności oraz akceptuje
              jej treść;
            </li>
            <li>
              może wyrazić zgodę na przetwarzanie danych osobowych w celach
              marketingowych polegających na przedstawianiu usług spółek z grupy
              Usługodawcy za pośrednictwem wybranego przez Użytkownika kanału
              komunikacji;
            </li>
            <li>
              podaje swoje dane osobowe, tj.: adres e-mail, numer telefonu, imię
              i nazwisko, adres korespondencyjny.
            </li>
          </ol>
          <li>
            Celem uruchomienia Konta konieczna jest jego aktywacja poprzez
            kliknięcie w link aktywacyjny wysłany na wskazany podczas
            rejestracji adres e-mail.
          </li>
          <li>
            Użytkownik może dokonać rejestracji w Serwisie wyłącznie w imieniu
            własnym bądź na podstawie umocowania podmiotu, który reprezentuje.
            Jeżeli Użytkownik dokonuje czynności w imieniu osoby trzeciej
            domniemywa się, że posiada ważne, w świetle obowiązujących przepisów
            prawa, pełnomocnictwo, do dokonania w jej imieniu czynności w
            Serwisie.
          </li>
          <li>
            W trakcie rejestracji Konta, jak również podczas korzystania z
            Serwisu Użytkownik jest obowiązany każdorazowo do podania
            prawdziwych, aktualnych i kompletnych danych. Użytkownik ponosi
            pełną odpowiedzialność za skutki podania nieprawdziwych,
            nieaktualnych czy niekompletnych danych. Ponadto Użytkownik
            zobowiązuje się do każdorazowej aktualizacji danych udostępnionych
            Usługodawcy oraz ponosi pełną odpowiedzialność za skutki zaniechań w
            tym względzie.
          </li>
          <li>Jeden Użytkownik może posiadać tylko jedno Konto w Serwisie</li>
          <li>
            Użytkownik posiadający Konto może administrować zamieszczonymi w nim
            swoimi danymi i dokonywać ich zmian zgodnie z aktualnie dostępną
            funkcjonalnością Serwisu.
          </li>
          <li>
            W zależności od zakresu aktywności Użytkownika w ramach Serwisu,
            Usługodawca zastrzega sobie prawo do zwrócenia się do Użytkownika o
            uzupełnienie danych profilowych.
          </li>
          <li>
            Zabrania się korzystania z Konta osoby trzeciej, jak również
            udostępniania swojego Konta do korzystania osobie trzeciej.
            Użytkownik jest zobowiązany do zachowania danych do Konta w
            poufności. Udostępnienie danych dostępowych do Konta osobom trzecim
            następuje na ryzyko Użytkownika, który ponosi pełną odpowiedzialność
            w tym względzie.
          </li>
          <li>
            Użytkownik nie może przenieść praw i obowiązków wynikających z
            rejestracji Konta oraz korzystania z Usług na rzecz osoby trzeciej.
          </li>
          <li>
            Użytkownik posiada dostęp do Konta za pośrednictwem panelu klienta
            dostępnego w Serwisie.
          </li>
          <li>
            Użytkownik posiadający Konto może je w każdym czasie usunąć.
            Likwidacja Konta nie jest równoznaczna z usunięciem z Serwisu
            wszelkich treści pochodzących od Użytkownika.
          </li>
          <li>
            Użytkownik ma prawo żądania tzw. „bycia zapomnianym” przez co
            rozumie się usunięcie wszelkich danych zawierających jego dane
            osobowe, zgodnie z RODO.
          </li>
        </ol>

        <h3>§ 6 Usługi świadczone przez Usługodawcę</h3>
        <ol>
          <li>
            Umowa o świadczenie Usług pomiędzy Usługodawcą a Użytkownikiem
            zostaje zawarta na czas nieokreślony z chwilą rejestracji Konta w
            Serwisie.
          </li>
          <li>Usługodawca świadczy Usługi zgodnie z Regulaminem.</li>
          <li>Usługodawca w ramach świadczenia usług drogą elektroniczną:</li>
          <ol>
            <li>
              umożliwia Użytkownikowi rejestrację Konta w Serwisie oraz
              utworzenie i utrzymanie indywidualnego Konta Użytkownika;
            </li>
            <li>
              umożliwia Użytkownikowi wyświetlanie w Serwisie informacji oraz
              materiałów dotyczących realizowanego przez Usługodawcę
              przedsięwzięcia oraz zapoznanie się z nimi,
            </li>
            <li>
              umożliwia Użytkownikowi zapoznanie się dokumentami dotyczącymi
              Inwestycji oraz dokumentami określającymi warunki i zasady
              zawarcia Umowy inwestycyjnej pożyczka, w tym sposobu dokonywania
              płatności;
            </li>
          </ol>
          <li>
            Regulamin nie określa w żaden sposób praw i obowiązków ani
            jakichkolwiek innych elementów stosunku prawnego istniejącego
            pomiędzy Użytkownikiem, Usługodawcą a innymi podmiotami.
          </li>
          <li>Usługodawca w ramach Serwisu:</li>
          <ol>
            <li>
              nie prowadzi działalności gospodarczej w charakterze biura usług
              płatniczych ani instytucji płatniczej w rozumieniu Ustawy z dnia
              19 sierpnia 2011 r. o usługach płatniczych;
            </li>
            <li>
              nie prowadzi działalności maklerskiej w rozumieniu Ustawy z dnia
              29 lipca 2005 r. o obrocie instrumentami finansowymi;
            </li>
            <li>
              nie organizuje ani nie prowadzi systemu obrotu instrumentami
              finansowymi w rozumieniu art. 3 pkt 9a Ustawy o obrocie
              instrumentami finansowymi, w szczególności alternatywnego systemu
              obrotu instrumentami finansowymi w rozumieniu Ustawy z dnia 29
              lipca 2005 r. o obrocie instrumentami finansowymi;
            </li>
            <li>
              nie prowadzi zbiórki publicznej w rozumieniu Ustawy z dnia 14
              marca 2014 r. o zasadach prowadzenia zbiórek publicznych;
            </li>
            <li>
              nie jest dostawcą usług finansowania społecznościowego w
              rozumieniu Ustawy z dnia 7 lipca 2022 r. o finansowaniu
              społecznościowym dla przedsięwzięć gospodarczych i pomocy
              kredytobiorcom oraz rozporządzenia Parlamentu Europejskiego i Rady
              (UE) 2020/1503 z dnia 7 października 2020 r. w sprawie
              europejskich dostawców finansowania społecznościowego dla
              przedsięwzięć gospodarczych oraz zmieniające rozporządzenie (UE)
              2017/1129.
            </li>
          </ol>
          <li>
            Użytkownik, który zarejestrował Konto w Serwisie jako konsument w
            rozumieniu odrębnych przepisów, albo jako osoba fizyczna w ramach
            prowadzonej działalności gospodarczej (jeśli zarejestrowanie Konta w
            serwisie nie ma dla tej osoby charakteru zawodowego), może odstąpić
            od zwartej umowy, składając Usługodawcy stosowne oświadczenie o
            odstąpieniu. Oświadczenie o odstąpieniu może zostać złożone na
            piśmie lub drogą elektroniczną. Usługodawca niezwłocznie potwierdzi
            Użytkownikowi potwierdzenie otrzymania oświadczenia o odstąpieniu.
          </li>
          <li>
            Termin do odstąpienia od umowy upływa po 14 dniach od daty
            rejestracji Konta w Serwisie.
          </li>
          <li>
            W przypadku złożenia oświadczenia o odstąpieniu od umowy, umowa o
            świadczenie Usług uważana jest za niezwartą, a Usługodawca usunie
            Konto.
          </li>
          <li>
            Dostęp do Konta możliwy jest za pośrednictwem panelu klienta
            dostępnego w Serwisie.
          </li>
        </ol>

        <h3>§ 7 Zawieranie Umowy inwestycyjnej pożyczka</h3>
        <ol>
          <li>
            W celu zainwestowania środków w realizowaną przez Usługodawcę
            Inwestycję konieczne jest zawarcie Umowy inwestycyjnej pożyczka.
          </li>
          <li>
            Umowa inwestycyjna pożyczka może być zawarta przez osobę fizyczną
            albo osobę prawną lub jednostkę organizacyjną niebędącą osobą
            prawną, której ustawa przyznaje zdolność prawną.
          </li>
          <li>W celu zawarcia Umowy inwestycyjnej pożyczka konieczna jest:</li>
          <ol>
            <li>rejestracja Konta w Serwisie</li>
            <li>zalogowanie się do Konta w Serwisie;</li>
            <li>wskazanie kwoty pożyczki;</li>
            <li>
              podanie przez Użytkownika wymaganych danych, w tym danych
              osobowych/danych osoby prawnej, danych kontaktowych oraz terminu
              preferowanego spotkanie u notariusza, w przypadku Umowy
              inwestycyjnej pożyczka powyżej 30.000,00 zł;
            </li>
            <li>
              szczegółowe zapoznanie się i zaakceptowanie treści wygenerowanych
              dokumentów, w szczególności Umowy inwestycyjnej pożyczka,
              określającej wszelkie prawa i obowiązki Stron Umowy inwestycyjnej
              pożyczka, związane z Inwestycją oraz wszystkimi załącznikami do
              Umowy inwestycyjnej pożyczka;
            </li>
            <li>kliknięcie przycisku ,,Zawieram umowę”.</li>
          </ol>
          <li>
            Do zawarcia Umowy inwestycyjnej pożyczka na warunkach w niej
            określonych dochodzi z chwilą kliknięcie przycisku ,,Zawieram
            umowę”.
          </li>
          <li>
            Użytkownik, w terminie 3 dni od zawarcia Umowy inwestycyjnej
            pożyczka, zobowiązany jest do wpłaty na rzecz Usługodawcy kwoty
            pożyczki.
          </li>
          <li>Wpłaty dokonywane są na rzecz Usługodawcy:</li>
          <ol>
            <li>
              bezpośrednio na rachunek bankowy Usługodawcy wskazany w panelu
              Użytkownika;
            </li>
            <li>
              za pośrednictwem podmiotu posiadającego zezwolenie na prowadzenie
              działalności w charakterze krajowej instytucji płatniczej wydane
              przez Komisję Nadzoru Finansowego i zarejestrowanego w Rejestrze
              Usług Płatniczych, zgodnie z Ustawą z dnia 19 sierpnia 2011 r. o
              usługach płatniczych.
            </li>
          </ol>
          <li>
            W przypadku zawarcia Umowy inwestycyjnej pożyczka powyżej 30.000,00
            zł, po jej zawarciu, celem ustanowienia opisanych w niej
            zabezpieczeń, Użytkownik zobowiązany jest stawić się u notariusza w
            ustalonym terminie.
          </li>
          <li>
            Wszelkie prawa i obowiązki Użytkownika, który udzielił pożyczki
            reguluje Umowa inwestycyjna pożyczka.
          </li>
        </ol>

        <h3>§ 8 Zakaz działań bezprawnych</h3>
        <ol>
          <li>
            Użytkownik może korzystać z Serwisu i świadczonych za jego
            pośrednictwem Usług wyłącznie w sposób zgodny z ich przeznaczeniem i
            funkcjonalnościami określonymi w Regulaminie.
          </li>
          <li>
            Użytkownik nie może korzystać z Serwisu ani świadczonych za jego
            pośrednictwem Usług w celach sprzecznych z przepisami prawa,
            zasadami współżycia społecznego, dobrymi obyczajami oraz powszechnie
            przyjętymi zasadami postępowania. W szczególności zakazane jest:
          </li>
          <ol>
            <li>
              dostarczanie przez Użytkownika treści o charakterze bezprawnym,
              obraźliwym, treści nieprawdziwych lub mogących wprowadzić w błąd;
            </li>
            <li>
              korzystanie przez Użytkownika z Serwisu i świadczonych za jego
              pośrednictwem Usług w celu destabilizacji pracy, poufności lub
              integralności Serwisu, innych systemów komputerowych należących do
              Usługodawcy lub osób trzecich, naruszanie integralności lub
              poufności danych zgromadzonych w takich systemach Serwisu ani
              świadczonych za jego pośrednictwem Usług;
            </li>
          </ol>
          <li>
            W przypadku przesłania treści określonych powyżej Usługodawca ma
            prawo rozwiązać umowę o świadczenie Usług ze skutkiem
            natychmiastowym i usunąć Konto Użytkownika.
          </li>
        </ol>

        <h3>§ 9 Odpowiedzialność Usługodawcy</h3>
        <ol>
          <li>
            Usługodawca nie odpowiada za szkody powstałe w wyniku korzystania
            przez Użytkowników z Serwisu w sposób niezgodny z postanowieniami
            Regulaminu lub przepisami prawa.
          </li>
          <li>
            Usługodawca nie gwarantują nieprzerwanej i niezakłóconej dostępności
            Serwisu (lub jego poszczególnych części).
          </li>
          <li>
            Usługodawca jest uprawniony do przerw w dostępności i korzystaniu z
            Serwisu, w szczególności gdy jest to spowodowane:
          </li>
          <ol>
            <li>
              koniecznością naprawy, rozbudowy, modyfikacji lub konserwacji
              sprzętu albo oprogramowania
            </li>
            <li>
              przyczynami niezależnymi od Usługodawcy, takimi jak siła wyższa
              czy działania lub zaniechania osób trzecich.
            </li>
          </ol>
          <li>
            Usługodawca dołoży starań, aby w miarę istniejących możliwości
            poinformować Użytkownika z odpowiednim wyprzedzeniem o planowanych
            przerwach w dostępnie do Serwisu oraz świadczonych za jego
            pośrednictwem Usług drogą elektroniczną, poprzez zamieszczenie
            informacji na stronie Serwisu lub przesłanie informacji na adres
            poczty elektronicznej podany przez Użytkownika podczas rejestracji.
          </li>
          <li>
            Usługodawca nie gwarantuje, że Serwis jest kompletny i nie zawiera
            błędów lub nieścisłości ani nie gwarantuje, że wszelkie błędy lub
            nieścisłości w Serwisie zostaną niezwłocznie usunięte.
          </li>
          <li>
            Usługodawca dokłada starań, aby informacje, które od niego pochodzą
            i są publikowane na stronach Serwisu były rzetelne, kompletne,
            zgodne z prawdą i aktualne. Usługodawca nie może jednak
            zagwarantować, że każda informacja spełnia te warunki.
          </li>
          <li>Usługodawca nie ponosi odpowiedzialności za:</li>
          <ol>
            <li>
              sposób, w jaki Użytkownicy korzystają z Serwisu, ani za
              jakiekolwiek wynikłe z tego skutki;
            </li>
            <li>
              treści zamieszczane w Serwisie przez Użytkowników i szkody wynikłe
              z tego tytułu;
            </li>
            <li>
              jakiekolwiek szkody, które wynikają z działań Użytkownika
              niezgodnych z obowiązującymi przepisami prawa lub postanowieniami
              Regulaminu;
            </li>
            <li>
              skutki wykorzystania przez Użytkowników materiałów oraz za ich
              przydatność dla Użytkowników.
            </li>
          </ol>
          <li>
            Zarejestrowany Użytkownik ponosi wyłączną odpowiedzialność za
            udostępnienie swojego Konta osobom trzecim oraz za nie zachowanie w
            tajemnicy danych logowania do Konta. Usługodawca nie odpowiada za
            zdarzenia wynikłe na skutek udostępnienia przez Użytkownika danych
            Konta osobie trzeciej lub też na skutek wejścia w posiadanie takich
            danych przez osobę trzecią bez wiedzy lub woli Użytkownika.
          </li>
          <li>
            Usługodawca nie ponosi odpowiedzialności za podanie przez
            Użytkownika nieprawidłowego adresu e-mail, na który wysyłane jest
            potwierdzenie rejestracji Konta, wzory dokumentów związanych z
            zawieranymi umowami.
          </li>
          <li>
            Usługodawca nie ponosi odpowiedzialności za jakiekolwiek szkody
            poniesione przez Użytkownika spowodowane zagrożeniami występującymi
            w sieci Internet, w szczególności włamaniami do systemu Użytkownika,
            przejęciami haseł przez osoby trzecie, zainfekowaniem systemu
            Użytkownika wirusami.
          </li>
          <li>
            Usługodawca nie ponosi odpowiedzialności za skutki wynikłe z podania
            przez Użytkownika nieprawdziwych, nieprawidłowych, niekompletnych
            informacji, jak również za skutki podania danych osób trzecich bez
            ich zgody lub wiedzy. Za skutki wynikające z podania nieprawdziwych,
            nieprawidłowych, niekompletnych informacji lub podania danych osób
            trzecich bez ich zgody lub wiedzy wyłączną odpowiedzialność ponosi
            Użytkownik.
          </li>
          <li>
            Usługodawca nie ponosi odpowiedzialności z tytułu zablokowania
            dostępu do usług, o którym mowa w § 9 ust. 4 Regulaminu.
          </li>
          <li>
            Postanowienia niniejszego paragrafu nie naruszają innych postanowień
            dotyczących odpowiedzialności Usługodawcy zawartych w Regulaminie.
          </li>
        </ol>

        <h3>
          Użytkownik ma prawo składać reklamacje w sprawach dotyczących Usług
          świadczonych przez Usługodawcę.
        </h3>
        <ol>
          <li>Reklamacje można składać w następujący sposób:</li>
          <ol>
            <li>
              na piśmie przesyłką pocztową na adres Trust Investment Projekt 14
              Spółka z ograniczoną odpowiedzialnością z siedzibą w Kielcach, ul.
              Robotnicza nr 1, 25-662 Kielce;
            </li>
            <li>za pośrednictwem formularza znajdującego się na stronie:</li>
          </ol>
          <li>
            Prawidłowo złożona reklamacja powinna zawierać następujące dane:
          </li>
          <ol>
            <li>
              oznaczenie Użytkownika (imię, nazwisko, adres do korespondencji);
            </li>
            <li>
              okoliczności uzasadniające reklamację oraz roszczenie z nich
              wynikające.
            </li>
          </ol>
          <li>
            Usługodawca rozpoznaje reklamację w terminie 14 dni od dnia jej
            otrzymania.
          </li>
          <li>
            Odpowiedź Usługodawcy na reklamację zostanie udzielona na piśmie (w
            przypadku złożenia reklamacji na piśmie) albo pocztą elektroniczną
            (w przypadku złożenia reklamacji za pośrednictwem poczty
            elektronicznej).
          </li>
        </ol>

        <h3>§ 11 Rozwiązanie umowy</h3>
        <ol>
          <li>
            Użytkownik może w każdej chwili zakończyć korzystanie z Usług. W
            przypadku zakończenia przez Użytkownika korzystania z Usług, umowa o
            świadczenie usług drogą elektroniczną rozwiązuje się bez
            konieczności składania dodatkowych oświadczeń z chwilą opuszczenia
            stron Serwisu, z zastrzeżeniem, że jeżeli Użytkownik założył Konto w
            Serwisie umowa o świadczenie usług drogą elektroniczną rozwiąże się
            z dniem usunięcia Konta.
          </li>
          <li>
            Konto może zostać usunięte w każdym czasie, na żądanie Użytkownika
            zgłoszone drogą elektroniczną.
          </li>
          <li>
            Rozwiązanie umowy o świadczenie usług drogą elektroniczną następuje
            ze skutkiem natychmiastowym. Usunięcie konta nie powoduje
            rozwiązania Umowy inwestycyjnej pożyczka i oraz nie zwalnia
            Użytkownika z obowiązku wykonania innych zobowiązań powstałych w
            związku z korzystaniem z Serwisu. Zawarte Umowy inwestycyjne
            pożyczka wywołują skutki w nich przewidziane.
          </li>
          <li>
            Usługodawca ma prawo rozwiązać umowę o świadczenie Usług ze skutkiem
            natychmiastowym i usunąć Konto, w przypadku naruszenia przez
            Użytkownika postanowień Regulaminu.
          </li>
          <li>
            Usługodawca ma prawo do zablokowania Użytkownikowi dostępu do Konta,
            w przypadku powzięcia uzasadnionego podejrzenia o naruszeniu przez
            Użytkownika postanowień Regulaminu, w szczególności w wyniku
            otrzymania wiarygodnej wiadomości o naruszeniu przez Użytkownika
            Regulaminu, na czas niezbędny dla wyjaśnienia sprawy i potwierdzenia
            czy do naruszenia przez Użytkownika postanowień Regulaminu
            faktycznie doszło.
          </li>
          <li>
            W przypadku zablokowania konta Użytkownika po wyjaśnieniu sprawy
            Usługodawca:
          </li>
          <ol>
            <li>
              w przypadku potwierdzenia, że do naruszenia przez Użytkownika
              postanowień Regulaminu nie doszło – dokona odblokowania Konta;
            </li>
            <li>
              w przypadku potwierdzenia, że do naruszenia przez Użytkownika
              postanowień Regulaminu faktycznie doszło – rozwiąże umowę o
              świadczenie Usług ze skutkiem natychmiastowym i usunie Konto.
            </li>
          </ol>
          <li>
            Usługodawca na prawo rozwiązać umowę o świadczenie Usług ze skutkiem
            natychmiastowym i usunąć Konto, w przypadku braku aktywności
            Użytkownika na Koncie w ciągu 12 następujących po sobie miesięcy.
            Przez brak aktywności Użytkownika rozumie się brak logowania się na
            Konto w okresie wskazanym powyżej.
          </li>
          <li>
            Usługodawca przesyła oświadczenie o zablokowaniu Konta bądź
            rozwiązaniu umowy o świadczenie Usług drogą elektroniczną, na adres
            poczty elektronicznej podany przez Użytkownika podczas rejestracji.
          </li>
          <li>
            Użytkownik przyjmuje do wiadomości i akceptuje, że usunięcie Konta
            jest równoznaczne z utratą przez Użytkownika dostępu do
            zgromadzonych w ramach tego Konta danych. Usługodawca zastrzega
            sobie prawo przechowywania danych w zakresie niezbędnym w oparciu o
            prawnie uzasadnione interesy Usługodawcy, w tym dla ustalenia,
            dochodzenia lub obronie roszczeń oraz zapobieganiu oszustwom i
            nadużyciom. Szczegółowe informacje w zakresie przetwarzania danych
            osobowych zawarte zostały w Polityce Prywatności.
          </li>
          <li>
            Usługodawca może z ważnych przyczyn zakończyć działanie Serwisu lub
            świadczenie Usług za jego pośrednictwem, o czym poinformuje
            Użytkowników drogą elektroniczną, poprzez komunikat opublikowany na
            stronie Serwisu oraz w, wypadku zarejestrowanych Użytkowników, za
            pośrednictwem poczty elektronicznej – przesyłając oświadczenie o 11
            rozwiązaniu umowy o świadczenie Usług drogą elektroniczną, na adres
            poczty elektronicznej podany przez Użytkownika podczas rejestracji.
          </li>
          <li>
            Usługodawca zastrzega sobie prawo do zmiany domeny Serwisu (adresu
            strony, za pomocą którego Użytkownicy korzystają z Serwisu). O
            zmianie domeny Usługodawca poinformuje Użytkownika drogą
            elektroniczną, poprzez komunikat opublikowany na stronie Serwisu
            oraz w wypadku zarejestrowanych Użytkowników, za pośrednictwem
            poczty elektronicznej.
          </li>
        </ol>

        <h3>§ 12 Zmiany Regulaminu</h3>
        <ol>
          <li>
            Usługodawca zastrzega sobie prawo do zmiany Regulaminu, w przypadku
            wystąpienia przynajmniej jednej z następujących ważnych przyczyn:
          </li>
          <ol>
            <li>
              konieczność dostosowania Regulaminu do przepisów prawa, mających
              bezpośredni wpływ na Regulamin i skutkujących koniecznością
              zmodyfikowania Regulaminu w celu zachowania zgodności z prawem;
            </li>
            <li>
              konieczność dostosowania Regulaminu do zaleceń, nakazów, orzeczeń,
              postanowień, interpretacji, wytycznych lub decyzji uprawnionych
              władz publicznych;
            </li>
            <li>
              rozbudowa lub zmiana funkcjonalności Serwisu, w tym wprowadzenie
              nowych usług świadczonych drogą elektroniczną lub zmiana
              istniejących funkcjonalności Serwisu;
            </li>
            <li>
              zmiana warunków technicznych świadczenia usług drogą
              elektroniczną;
            </li>
            <li>
              konieczność usunięcia niejasności, błędów lub omyłek pisarskich,
              które ewentualnie wystąpiłyby w Regulaminie;
            </li>
            <li>zmiana danych Usługodawcy w Regulaminie;</li>
            <li>zmiana procesu zawierania umów za pośrednictwem Serwisu.</li>
          </ol>
          <li>
            O zmianie Regulaminu Usługodawca poinformuje Użytkownika drogą
            elektroniczną, poprzez komunikat opublikowany na stronie Serwisu
            oraz w wypadku zarejestrowanych Użytkowników, za pośrednictwem
            poczty elektronicznej, nie później niż 30 dni przed proponowaną datą
            wejścia w życie zmian.
          </li>
          <li>
            Zmiana Regulaminu zaczyna obowiązywać w terminie wskazanym w
            powiadomieniu, chyba że Użytkownik, który nie akceptuje
            wprowadzonych w Regulaminie zmian, w terminie 14 dni od publikacji
            na stronie Serwisu komunikatu o zmianie Regulaminu albo otrzymania
            informacji o zmianie Regulaminu za pośrednictwem poczty
            elektronicznej, rozwiąże umowę o świadczenie Usług.
          </li>
          <li>
            Zmiany Regulaminu mogą wejść w życie wcześniej, jeżeli będzie to
            wymagane przepisami prawa lub wynikać z orzeczenia sądu lub decyzji
            organów administracji.
          </li>
          <li>
            W celu uchylenia wątpliwości wskazuje się, że zmiany Regulaminu nie
            powodują naruszenia praw nabytych Klientów.
          </li>
        </ol>

        <h3>§ 13 Prawa własności intelektualnej</h3>
        <ol>
          <li>
            Wszelkie treści, grafiki lub innego rodzaju materiały znajdujące się
            Serwisie są przedmiotem praw autorskich lub innych praw własności
            intelektualnej należących do Usługodawcy lub wykorzystywanych przez
            Usługodawcę na podstawie odrębnych umów.
          </li>
          <li>
            Użytkownik nie może wykorzystywać treści, grafik lub innego rodzaju
            materiałów znajdujących się w Serwisie (w całości lub w części) w
            celach komercyjnych bez uprzedniej zgody Usługodawcy, w
            szczególności Użytkownik nie może drukować, kopiować, modyfikować,
            pobierać na dysk twardy lub inny nośnik danych, a także zamieszczać
            w treści innych opracowań (np. dokumentach papierowych, publikacjach
            prasowych, stronach internetowych, blogach oraz mediach
            społecznościowych) treści, grafik lub innego rodzaju materiałów
            znajdujących się w Serwisie (w całości lub w części).
          </li>
        </ol>

        <h3>§ 11 Postanowienia końcowe</h3>
        <ol>
          <li>
            Regulamin obowiązuje od dnia jego publikacji na stronie Serwisu i
            jest dostępny za pośrednictwem odnośnika znajdującego się na stronie
            głównej Serwisu.
          </li>
          <li>
            Treść Regulaminu może zostać utrwalona poprzez wydrukowanie,
            zapisanie na nośniku lub pobranie w każdej chwili ze strony Serwisu.
          </li>
          <li>
            W sprawach nieuregulowanych w Regulaminie zastosowanie mają przepisy
            polskiej ustawy Kodeks cywilny oraz innych odpowiednich regulacji
            prawa polskiego.
          </li>
          <li>
            Jeżeli jedno z postanowień Regulaminu uznane zostanie za nieważne z
            mocy prawomocnego wyroku sądowego lub okaże się niemożliwe do
            zrealizowania, pozostałe postanowienia będą ważne i pozostaną w mocy
          </li>
          <li>
            Wszelkie spory powstałe na tle działalności prowadzonej za
            pośrednictwem Serwisu podlegają jurysdykcji sądów polskich a sądem
            właściwym miejscowo dla rozstrzygania sporów wynikających z
            korzystania przez Użytkowników z Serwisu jest sąd właściwy dla
            siedziby Usługodawcy. Zdanie poprzednie nie ma zastosowania do
            sporów, w których właściwość sądu regulowana jest bezwzględnie
            obowiązującymi przepisami prawa, w tym w sprawach dotyczących sporów
            z konsumentem.
          </li>
        </ol>
      </div>
    </section>
  )
}

export default Content
